/** @jsx jsx */
import { css } from '@emotion/react'

export const mobile_container = css`
margin-top: 86px;
position: relative;
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.4;
  background:  #161616;
  z-index:1;
}
`

export const customSection = css`
  position: absolute;
  z-index: 2;
  max-width: 635px;
  margin-left: 165px;
  @media (max-width: 800px) {
    z-index: 9;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 40px;
  }
  @media (max-width: 500px) {
    top: 0;
  }

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-right: 10px;
    margin-left: 10px;
  }
  > h1 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 100px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -5px;

    @media (max-width: 500px) {
      color: #E3E1E4;
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -1.8px;
    }
  }

  > p {
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
  }

  > a {
    border-radius: 56px;
    background: #5315FF;
    color: #fff;
    padding: 12px 24px; 
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    text-decoration: none;
  }
`
export const abAgency = css`
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  @media (min-width: 500px) and (max-width: 850px) {
    height: auto;
    align-items: center;
  }
  @media (min-width: 500px) and (max-width: 850px) {
    text-align: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
  }

  .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.4;
    background:  #161616;
    z-index:1;
  }
`
export const videoBg = css`
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 86px;

  h1 {
    
  }

  .video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    video {
      position: absolute;
      
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  .videoMobile {
    display: block;
    height: 800px !important;
    position: relative;
    /* top: -63px; */
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    /* video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    } */
  }
`