/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  abAgency,
  customSection,
  mobile_container,
  videoBg,
} from '../assets/styles/HomePageVideo.styles'
import { useEffect, useState } from 'react'
import Video from './video-background'
import SchoolVideo from '../assets/video/kingdom_school_small_video.mp4'
import SchoolVideoMobile from '../assets/video/kingdom_school_small_video_Mobi.mp4'
import { Link } from 'gatsby'

const HomePageVideoSection = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500)
    }

    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 500)
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return (
    <div>
      {
        isMobile ? (
          <div css={mobile_container}>
            <video width='100%' autoPlay muted loop playsInline>
              <source src={SchoolVideoMobile} type="video/mp4" />
            </video>
            <div css={customSection}>
              <h1 data-aos='fade-right'>
                Escola além <br /> da escolaa
              </h1>
              <p data-aos='fade-left'>
                Educação Infantil e Ensino <br /> Fundamental I e II
              </p>
              <Link href='/fale-conosco'>Agende uma visita</Link>
            </div >
            <div className='overlay' />
          </div>
        ) : (
          <section css={[abAgency, videoBg]}>
            <div css={customSection}>
              <h1 data-aos='fade-right'>
                Escola além da escola
              </h1>
              <p data-aos='fade-left'>
                Educação Infantil e Ensino Fundamental I e II
              </p>
              <Link href='/fale-conosco'>Agende uma visita</Link>
            </div >
            <div className='overlay' />
            <Video
              videoSrcURL={SchoolVideo}
            />
          </section >
        )
      }

    </div >

  )
}

export default HomePageVideoSection