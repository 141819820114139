import React from "react";
import Layout from "@/components/layout";
import EnsinoIntencional from "@/components/EnsinoIntencionalContent";
import EscolaDePresente from "@/components/EscolaDePresenteContent";
import CTAFacaParte from "@/components/CTAFacaParte";
import CTAEscolaAutoral from "@/components/CTAEscolaAutoralContent";
import DoMaternalAoFundamental from "@/components/DoMaternalAoFundamentalContent";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HomePageVideo from "../components/homePageVideo";
import HeaderOne from '@/components/header-one';
import Footer from "@/components/footer";
import CurriculoInternacionalIEYC from '@/components/CurriculoInternacionalIEYC';
import Sintonizados from '@/components/Sintonizados';
import MaisTempoQualidade from '@/components/MaisTempoQualidade';
import InstagramSlide from '@/components/InstagramSlide';
import SEO from '../components/seo'

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout>
          <SEO
            title="Escola em Brasília: Educação Infantil e Fundamental | Kingdom School"
            description="A Kingdom School oferece educação bilíngue de qualidade em Brasília, com foco no desenvolvimento integral da criança. Agende uma visita!"
            keywords={
              ['escola em brasília',
                'escola no df',
                'educação infantil brasília',
                'ensino fundamental brasília',
                'escola bilíngue',
                'metodologia glenn doman',
                'ieYC'
              ]
            }
          />
          <HeaderOne />
          <HomePageVideo />
          <EnsinoIntencional />
          <CTAEscolaAutoral />
          <DoMaternalAoFundamental />
          <CTAFacaParte />
          <CurriculoInternacionalIEYC />
          <Sintonizados />
          <MaisTempoQualidade />
          <EscolaDePresente />
          <InstagramSlide />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
