/** @jsx jsx */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { jsx } from "@emotion/react";
import { useMediaQuery } from 'react-responsive';
import {
  ensinoIntencional,
} from "../assets/styles/AboutTwo.styles";

import ImgEnsinoIntencional from "../assets/images/LP-kingdom/ensino-intencional.png";

const AboutTwo = () => {

  const [counter, setCounter] = useState({
    startCounter: false,
  });
  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setCounter({ startCounter: true });
    }
  };
  const isMobile = useMediaQuery({
    query: '(max-width: 850px)'
  })

  return (
    <section css={ensinoIntencional}>
      {isMobile && 
      <Col
      className="img-container px-0"
      data-aos="fade-right"
      lg={6}
      md={12}
      sm={12}
    >
      <img src={ImgEnsinoIntencional} alt=" " />
    </Col>}
      <Container className="conatainerTeaching" fluid>
        <Row className="title-content">
        {!isMobile &&
          <Col
          className="img-container px-0"
          data-aos="fade-right"
          lg={6}
          md={12}
          sm={12}
        >
          <img src={ImgEnsinoIntencional} alt=" " />
        </Col>}
          <Col lg={6} md={12} sm={12}>
            <div className="content-box">
              <h2>Ensino autoral, afetuoso e personalizado</h2>
              <p>Referência no Distrito Federal, a Kingdom é uma escola que entrega aprendizagem sólida em português e matemática & experiência multidisciplinar, ao revisitar e adaptar as tradições às novas demandas, ao comportamento humano e aos processos de aprendizado.</p>
              <div className="btn-section">
                <Link to="/educacao-infantil#estrutura-kids">{isMobile ? 'Educação Infantil' : 'Educação Infantil' }</Link>
                <Link to="/ensino-fundamental-i#estrutura-school">{isMobile ? 'Ensino Fundamental' : 'Ensino Fundamental' }</Link>
              </div>
            </div>
          </Col>        
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
