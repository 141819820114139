/** @jsx jsx */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import {
  escolaPresente,
  content,
} from "../assets/styles/AboutTwo.styles";

import ImgEscolaDePresente from "../assets/images/LP-kingdom/escola-de-presente.png";

const AboutTwo = () => {
  const [setCounter] = useState({
    startCounter: false,
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 850px)'
  })

  return (
    <section css={escolaPresente}>
      {isMobile && 
      <Col
        className="img-container px-0"
        data-aos="fade-right"
        lg={6}
        md={12}
        sm={12}
    >
      <img src={ImgEscolaDePresente} alt=" " />
    </Col>}
      <Container className="contentGift" fluid>
        <Row className="title-content">
        {!isMobile &&
          <Col
          className="img-container px-0"
          data-aos="fade-right"
          lg={6}
          md={12}
          sm={12}
        >
          <img src={ImgEscolaDePresente} alt=" " />
        </Col>}
          <Col css={content} lg={6} md={12} sm={12}>
            <div>
              <h1 style={{ maxWidth: 570 }}>Dê uma escola de presente para o seu filho</h1>
              <p>Venha nos visitar, caminhar pela estrutura e descobrir se esse sonho está alinhado com os sonhos que você busca para seus filhos.</p>
              <div className="btn-section">
                <Link to="/fale-conosco" style={{ color: '#3F3F3F' }}>Agende sua visita</Link>
                <Link to="/a-escola">Conheça a estrutura</Link>
              </div>
            </div>
          </Col>        
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
