/** @jsx jsx */
import React from "react";
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { Parallax } from 'react-parallax';
import BgSection2 from "../assets/images/LP-kingdom/quero-meu-filho-kd.png";
import BgSectionMobile from "../assets/images/LP-kingdom/Mask group.png";
import {ctaSectionFacaParte } from '../assets/styles/AboutTwo.styles'
import { Link } from "gatsby";

const CtaContent = () => {
  return (
    <Fragment>
      <section css={ctaSectionFacaParte}>
        <div>
          <h2 style={{ marginBottom: 32 }}>Quero meu filho na Kingdom!</h2>
          <Link to="/fale-conosco" target="_blank">Fazer pré-reserva</Link>
        </div>
      </section>
    </Fragment>
  )
}

export default CtaContent
