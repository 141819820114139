/** @jsx jsx */
import { css } from "@emotion/react";

import MaisQualidade from '../images/LP-kingdom/MaisTempo-image.png'
import MaisQualidadeMobile from '../images/LP-kingdom/mais-tempo-mobile.png'

export const ContainerStyled = css`
height: 100%;
max-height: 632px;
background-image: url(${MaisQualidade});
background-repeat: no-repeat;
background-size: cover;
padding: 158px 165px;
position: relative;

@media (max-width: 900px) {
  max-height: fit-content;
  padding: 516px 16px 151px 16px;
  background-image: url(${MaisQualidadeMobile});
  }

.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  background: linear-gradient(89deg, #161616 0.41%, rgba(63, 61, 61, 0.60) 54.88%, rgba(190, 186, 186, 0.00) 97.68%);
  mix-blend-mode: hard-light;
  z-index:0;
  @media (max-width: 900px) {
    background: linear-gradient(180deg, rgba(35, 35, 35, 0.00) 0.09%, #232323 31.2%);
  }
}

h1{
  margin-bottom: 41px;
  color: #FFF;
font-size: 36px;
font-weight: 600;
line-height: 100%;
letter-spacing: -1.8px;
}
p{
  max-width: 475px;
  margin-bottom: 56px;
  color:  #FFF;
font-size: 16px;
font-weight: 500;
line-height: 150%; 
}
a{
  color: #FFF;
text-align: center;
font-size: 16px;
font-weight: 600;
line-height: 120%;
background: #5315FF;
border-radius: 56px;
padding: 12px 24px;
}
`