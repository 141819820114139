/** @jsx jsx */
import { jsx } from "@emotion/react"
import { Link } from "gatsby";
import { ContainerStyled } from '../assets/styles/CurriculoInternacionalIEYC.styles'

const CurriculoInternacionalIEYC = () => {
  return (
    <div css={ContainerStyled}>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <h1>Currículo internacional IEYC</h1>
        <p>A Kingdom School é uma das 12 escolas brasileiras com a licença de uso da metodologia International Early Years Curriculum (IEYC), que  proporciona aos alunos a capacidade de se desenvolver em outra língua através de atividades eficientes e que ativam a criatividade e a conexão do aluno com seus interesses e habilidades.</p>
        <Link to="/a-escola#abordagem">Entenda o projeto bilíngue</Link>
      </div>
      <div className='overlay' />
    </div>
  )
}

export default CurriculoInternacionalIEYC
