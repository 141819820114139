/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { EducacaoInfantil, EnsinoFundamentalI, EnsinoFundamentalII } from "@/data";
import { Col, Container, Row } from "react-bootstrap";
import ArrowR from "../assets/images//LP-kingdom/arrow-r.svg"
import { Link } from "gatsby";

import { doMaternalAoFundamentalSection  } from '../assets/styles/AboutTwo.styles'

const CtaContent = () => {

  return (
    <Fragment>
      <section css={doMaternalAoFundamentalSection}>
        <Container>
          <Row style={{ marginBottom: 24 }}>
            <Col lg={12} md={12} sm={12}>
              <h2>Educ. Infantil e Ensino fundamental I e II</h2>
              <p style={{ textAlign: `center` }}>Referência em educação em Brasília</p>
            </Col>    
          </Row>
          <Row >
            <Col className="content" lg={4} md={4} sm={12}>
              <div className="box-content">
                <img src={EducacaoInfantil.light} alt="" style={{ width: '100%' }}/>
                <h3>Educação Infantil</h3>
                <p style={{ marginLeft: 16 }}>Pautada na neuroeducação</p>
                <ul>
                  <li>• Salas amplas</li>
                  <li>• Turmas com número reduzido de alunos</li>
                  <li>• Psicomotricidade</li>
                </ul>
                <div style={{ paddingLeft: 16, paddingBottom: 40 }}>
                  <Link to="/educacao-infantil">
                    CONHEÇA A PROPOSTA
                    <img src={ArrowR} alt="" />
                  </Link>
                </div>
              </div>
            </Col> 
            <Col className="content" lg={4} md={4} sm={12}>
              <div className="box-content">
                <img src={EnsinoFundamentalI.light} alt="" style={{ width: '100%' }}/>
                <h3>Ensino Fundamental I</h3>
                <p style={{ marginLeft: 16 }}>Programa de fluência em inglês</p>
                <ul>
                  <li>• Salas-laboratório</li>
                  <li style={{ marginBottom: 49 }}>• Salas com número de alunos reduzido</li>
                </ul>
                <div style={{ paddingLeft: 16, paddingBottom: 40 }}>
                  <Link to="/ensino-fundamental-i">
                    CONHEÇA A PROPOSTA
                    <img src={ArrowR} alt="" />
                  </Link>
                </div>
              </div>
            </Col> 
            <Col className="content" lg={4} md={4} sm={12}>
              <div className="box-content">
                <img src={EnsinoFundamentalII.light} alt="" style={{ width: '100%' }}/>
                <h3>Ensino Fundamental II</h3>
                <p className="competence">Horário Integral</p>
                <ul style={{ marginTop: 18 }}>
                  <li>• Programa bilíngue </li>
                  <li style={{ marginBottom: 50 }}>• Foco em excelência acadêmica e habilidades pessoais</li>
                </ul>
                <div style={{ paddingLeft: 16, paddingBottom: 40 }}>
                  <Link to="/ensino-fundamental-ii">
                    CONHEÇA A PROPOSTA
                    <img src={ArrowR} alt="" />
                  </Link>
                </div>
              </div>
            </Col>  
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default CtaContent