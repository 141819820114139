/** @jsx jsx */
import { css } from '@emotion/react'

export const containerCard = css`
  align-items: center;
  column-gap: 8px;
  display: grid;
  grid-template-areas: 'btnPrev slider btnRight';
  grid-template-columns: auto minmax(auto, 1200px) auto;
  margin: auto;
  max-width: 1282px;
  padding: 0 8px;
  @media (max-width: 850px) {
    column-gap: 0;
    grid-template-areas:
      'slider slider slider'
      '_ btnPrev btnRight';
    padding: 0;
    row-gap: 40px;
  }
  .swiper-pagination{
    bottom: -8px;
  }
  
  .swiper-pagination-bullets.swiper-pagination-horizontal{
    display: none;
  }
  .swiper-slide__container{
    width: auto;
    position: relative;
    display: flex;
    @media (max-width: 500px) {
      display: block;
      }
  }
  .swiper-wrapper{
    height:500px;
    padding-top: 0px;
    margin-left: 16px;
    @media (max-width: 500px) {
      height: 350px !important;
    }
  }
  .swiper {
    grid-area: slider;
    width: 150%;
    padding-right: 36px;
    @media (max-width: 500px){
        max-width: 6000px;
        width: 100%;
    }

    &-slide {
      margin-right: 0px;
      padding-top: 70px;
      width: 429px;
      -moz-transition: background-color 0.4s ease-out;
      -webkit-transition: background-color 0.4s ease-out;
      transition: background-color 0.4s ease-out;
      @media (max-width: 500px) {
      padding-top: 32px;
      padding-bottom: 0px;
      max-width: 305px;
      }

      &__container {
        text-align: center;
        .photoProfile {
          border-radius: 25px;
          overflow: hidden;
          max-height: 429px;
          img {
            width: 429px;
            height: auto;
            border-radius: 25px;
            @media (max-Width: 500px) {
              width: 300px;
              height: 300px;
              object-fit: cover;
              object-position: top;
            }
            
          }
        }

        .titles{
          display: flex ;
          justify-content: center;
          align-items: center;
          margin: 0 0 27px 0;
          padding: 0;
          h5{
            text-align: center;
            padding-left: 8px;
            margin-bottom: 0;
          }
          h3{
            margin-bottom: 0;
            color: #484848;
          }
          @media (max-Width: 500px) {
             display  :block ;
             text-align: left;
             margin: 20px 0 30px 0;
            h3{
              margin-bottom: 7px;
            }
          }
        }

       
        .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 15px;
            
            @media (max-Width: 500px) {
            margin-left: 0px;
               
            }
          h3 {
            color: #484848;
            font-family: "owners-narrow", sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-transform: uppercase;
            margin-top: 0px;
            @media (max-Width: 500px) {
            margin-top: 16px 0 8px;
            }
          }
          h5{
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            text-transform: uppercase;
            color: #484848;
            @media (max-Width: 500px) {
              line-height: 110%;
              text-transform: uppercase;
              color: #776AAE;
              text-align: left;
              padding-left: 0px;


            }

          }
          p {
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; 
            color : #484848;
            text-align: left;
            margin-bottom: 0px;
            @media (max-Width: 500px) {

            }
          }
          .textEx{
            font-size: 17.5px;
        }
        }
       
      }
      &:hover h5{
        color: #CFC5E5;
        -moz-transition: color 0.4s ease-out;
        -webkit-transition: color 0.4s ease-out;
        transition: color 0.4s ease-out;
        
      }
      &:hover p,
      &:hover h3{
        color: #EAECED ;
        -moz-transition: color 0.4s ease-out;
        -webkit-transition: color 0.4s ease-out;
        transition: color 0.4s ease-out;
      }

      @media (hover: none) {
        &:hover h5 {
            color: inherit; /* ou outra cor padrão */
            -moz-transition: none;
            -webkit-transition: none;
            transition: none;
        }

        &:hover p,
        &:hover h3 {
            color: inherit; /* ou outra cor padrão */
            -moz-transition: none;
            -webkit-transition: none;
            transition: none;
        }
      }
       



      @media (max-width: 850px) {
        &:first-of-type {
          margin-left: 0px;
        }

        &:last-of-type {
          margin-left: 8px;
          margin-right: 7px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-left: 8px;
        }
      }

      @media (max-width: 500px) {
        &:first-of-type {
          margin-left: 16px;
        }

        &:last-of-type {
          margin-left: 8px;
          margin-right: 16px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8),
        &:nth-of-type(9),
        &:nth-of-type(10),
        &:nth-of-type(11){
          margin-left: 20px;
        }
      }
    }
  }

  .btn {
    align-items: center;
    background: rgba(237, 240, 247, 0.99);
    border: none;
    border-radius: 80px;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    outline: none;
    width: 50px;

    &__icon {
      width: 100%;

      img {
        width: 100%;
      }
    }

    @media (max-width: 850px) {
      height: 36px;
      width: 36px;
    }

    @media (max-width: 500px) {
      background: inherit;
    }
  }
  .btn:hover {
    path {
      fill: #0c79fe;
    }
  }

  .slider-prev {
    grid-area: btnPrev;
    padding: 13px 20px 13px 17px;

    @media (max-width: 850px) {
      justify-self: end;
      margin-right: 40px;
      padding: 9px 14px 9px 12px;
    }

    @media (max-width: 500px) {
      margin-right: 20px;
    }
  }

  .slider-next {
    grid-area: btnRight;
    justify-self: end;
    padding: 13px 17px 13px 20px;

    @media (max-width: 850px) {
      justify-self: start;
      margin-right: 58px;
      padding: 9px 12px 9px 14px;
    }

    @media (max-width: 500px) {
      margin-right: 20px;
    }
  }
`
export const background = css`
  background: var(--Gray-3, #F5F5F5);

`

export const linkContainerStyle = css`
  background: var(--Gray-3, #F5F5F5);
  padding-top: 50px;
  display: flex;
  justify-content: center;
  a{
    align-items: center;
    width: 180px;
    background-color:  #5315FF;
    padding: 11px 11px;
    margin-bottom: 100px;
    margin-top: 0px;
    border-radius: 56px;
    color: var(--color-white-100-kingdom-1, #FFF);
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;  
    text-decoration: none;
  }
`