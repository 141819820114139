/** @jsx jsx */
import React from 'react'
import { jsx } from "@emotion/react"
import { Link } from "gatsby";
import { ContainerStyled } from '../assets/styles/MaisTempoQualidade.styles'
 
const MaisTempoQualidade = () => {
  return (
    <div css={ContainerStyled}>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <h1>Mais tempo de qualidade</h1>
        <p>Após o horário regular, o aluno pode se dedicar a atividades extracurriculares voltadas para artes e esportes no Contraturno. </p>
        <Link to="/a-escola#abordagem">Conheça o contraturno</Link>
      </div>
      <div className='overlay' />
    </div>
  )
}

export default MaisTempoQualidade
