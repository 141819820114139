/** @jsx jsx */
import { css } from "@emotion/react";

import SintonizadosImage from '../images/LP-kingdom/sintonizados-image.png'
import SintonizadosImageMobile from '../images/LP-kingdom/sintonizados-mobile.png'

export const ContainerStyled = css`
height: 100%;
background-image: url(${SintonizadosImage});
background-repeat: no-repeat;
background-size: cover;
padding: 99px 165px;
position: relative;

@media (max-width: 900px) {
  background-image: url(${SintonizadosImageMobile});
  padding: 331px 16px 71px 16px;
}
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  background: linear-gradient(89deg, #161616 0.41%, rgba(63, 61, 61, 0.60) 54.88%, rgba(190, 186, 186, 0.00) 97.68%);
  mix-blend-mode: hard-light;
  z-index:0;
  @media (max-width: 900px) {
    background: linear-gradient(180deg, rgba(35, 35, 35, 0.00) 0.09%, #232323 31.2%);
}
}

h1:not(:first-of-type) {
  margin-bottom: 41px;
}
h1{
  color: #FFF;
  font-size: 36px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.8px;
}
p{
  max-width: 475px;
  margin-bottom: 56px;
  color:  #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; 
}
a{
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  background: #5315FF;
  border-radius: 56px;
  padding: 12px 24px;
}

.manifesto{
  display: flex;
  gap: 30px;
  max-width: 475px;
  margin-bottom: 56px;
  h4 {
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  p {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%; 
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
    gap: 24px;
  }
}

.manifesto_container{
  display: flex;
  flex-direction: column;
}
`