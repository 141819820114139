/** @jsx jsx */
import React from 'react'
import { jsx } from "@emotion/react"
import { Link } from "gatsby";
import { ContainerStyled } from '../assets/styles/Sintonizados.styles'

const Sintonizados = () => {
  return (
    <div css={ContainerStyled}>
      <div style={{ position: 'relative', zIndex: 1}}>
        <h1>Sintonizados com</h1>
        <h1>as melhores práticas educacionais</h1>
        <p>Na Kingdom, mente e corpo são desenvolvidos por meio de abordagens curriculares dinâmicas. Nossos pilares são alicerçados em três princípios: desenvolvimento pleno, segurança e afeto.</p>
        <div className='manifesto'>
          <div className='manifesto_container'>
            <h4>AFETO</h4>
            <p>Com si mesmo e com o próximo</p>
          </div>
          <div>
            <h4>SEGURANÇA</h4>
            <p>Física e Emocional</p>
          </div>
          <div>
            <h4>DESENVOLVIMENTO</h4>
            <p>De como ser humano</p>
          </div>
        </div>
        <Link to="/a-escola#imagine-uma-escola">Manifesto Kingdom</Link>
      </div>
      <div className='overlay' />
    </div>
  )
}

export default Sintonizados
