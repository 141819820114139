/** @jsx jsx */
import React from "react";
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { Parallax } from 'react-parallax';
import { Col, Container, Row } from "react-bootstrap";
import BgSection2 from "../assets/images/LP-kingdom/escola-autoral.png";
import { Link } from "gatsby";

import { ctaSectionEscolaAutoral, content } from '../assets/styles/AboutTwo.styles'

const CtaContent = () => {

  return (
    <div css={ctaSectionEscolaAutoral}>
      <div className='desktop'>
        <Parallax bgImage={BgSection2} bgImageAlt="the cat" strength={300}>
          <section>
            <Container>
              <Row className="title-content">
                <Col lg={6} md={12} sm={12}>
                  <div className='contet-box'>
                    <h2>Imagine aprender de verdade, aprender com solidez, aprender a gostar de aprender!</h2>
                    <p style={{ marginBottom: 40 }}>Abordagem atualizada, personalizada e focada no desenvolvimento completo da criança. Na Kingdom, trabalhamos a filosofia do Método Glenn Doman, que compreende o processo de estimulação essencial a partir do nascimento.</p>
                    <Link to="/a-escola#abordagem">Metodologia Kingdom</Link>
                  </div>
                </Col>
                <Col css={content} lg={6} md={12} sm={12} />
              </Row>
            </Container>
          </section>
        </Parallax>
      </div>

      <div className='mobile'>
          <section >
            <Container fluid>
              <Row className="title-content">
                <Col css={content} lg={6} md={12} sm={12}>
                  <div>
                    <h2>Imagine aprender de verdade, aprender com solidez, aprender a gostar de aprender!</h2>
                    <p style={{ marginBottom: 40 }}>Abordagem atualizada, personalizada e focada no desenvolvimento completo da criança. Na Kingdom, trabalhamos a filosofia do Método Glenn Doman, que compreende o processo de estimulação essencial a partir do nascimento.</p>
                    <Link to="/a-escola#abordagem">Metodologia Kingdom</Link>
                  </div>
                </Col>
                <Col css={content} lg={6} md={12} sm={12} />
              </Row>
            </Container>
          </section>
      </div>

    </div>
  )
}

export default CtaContent